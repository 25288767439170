<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">List Members</h4> 
            <a href="/#/user/add" class="float-right btn btn-warning form-control-sm">Add Member</a>

          </slot>
        </CCardHeader>
        <CCardBody>

        <div class="form-group">
          <CInput
              placeholder="Cari Member..."
              v-model="cari"
              v-on:keyup="loadSearch($event)"
            >
              <template #append-content> <CIcon name="cil-magnifying-glass"/></template>
            </CInput>
        </div>

        <CCard accent-color="secondary" v-for="row in items" :key="row.no" v-if="itemlengths>0">
          <CCardBody style="font-size: 11px;" class="">
            <CRow>
              <CCol xs="12">
                <div class="text-left px-2"><strong>{{row.member_email}}</strong><br><div v-html="row.status"></div></div>
              </CCol>
              <CCol xs="12">
                <div class="text-left px-2"><strong><span class='text-info'>{{row.member_wallet}} USDT</span></strong><br><span class='text-secondary'>{{row.last_activity}}</span></div>
              </CCol>
            </CRow>
            <CRow class="mt-2">
            <div class="col-xs-6 col-6">
                <button class="btn btn-danger btn-block w-100 btn-sm" @click="showBlockConfirmation(row.member_email)">Block</button>
            </div>
            <div class="col-xs-6 col-6">
                <button class="btn btn-light btn-block w-100 btn-sm" @click="edit(row.id_member)" >Edit</button>
            </div>
          </CRow>

            <div v-if="row.link != ''" v-html="row.link"></div>
            <!-- <div > {{row.link}}</div> -->
          </CCardBody>
        </CCard>

        <CCard accent-color="danger" v-if="itemlengths==0">
          <CCardBody style="font-size: 11px;">
            <CRow>
              <CCol>
                <div class="text-center"><strong>EMPTY MEMBERS. </strong></div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CButton block color="light" @click="loadMore()">Load More....</CButton>
      
        <!-- <table class="table" id="datatable">
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Name </th>
              <th>Email </th>
              <th>Country </th>
              <th>Current Assets </th>
              <th>Last Activity </th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>   -->

        <CSpinner v-show="mySpinner" color="primary" style="position:fixed;left: 50%;top:50%"/>

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>


    <CModal title="Success" :show.sync="mySuccess" size="sm">
      Success! The member has blocked.
      <template #footer>
        <CButton @click="mySuccess=false" color="dark">OK</CButton>
    </template>
    </CModal>

    <CModal title="Confirmation" :show.sync="myBlock" size="sm">
      Are you sure want to block {{member_email}}?
      <template #footer>
        <CButton @click="myBlock = false" color="secondary">Cancel</CButton>
        <CButton @click="blockUser()" color="dark">OK</CButton>
    </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
import { func } from 'prop-types';



export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            items:[],
            mySpinner : false,
            mySuccess : false,
            myBlock:false,
            cari : "",
            itemlengths:0,
            id_member:0,
            member_email:'',
            length : 100,
            start : 0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    loadMore: function() {
      this.length = this.length + 100;
      // this.start = this.start + 100;
      this.loadData();
    },
    edit:function(_idMember){
      this.$router.push('/members/edit/'+_idMember);
    },
    loadSearch: function(event) {
      var key = event.keyCode || event.charCode;
      if( key == 8 ){
        //backspace pressed
        this.loadData();  
      }
      if(this.cari.length>2){
        this.loadData();  
      }
      
    },
    showBlockConfirmation: function(email){
      let newString =email.split(" ");
      this.member_email = newString[0];
      this.myBlock=true;
    },
    blockUser: function(){
      // Simple POST request with a JSON body using axios
      let newString = this.member_email.split(" ");
      let email = newString[0];
      const user = { 
                          member_email: email,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"blockmember", user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data=='sukses'){
              this.myBlock = false;
              this.mySuccess = true;
              // this.$router.push('/members');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {
      this.mySpinner = true;
        const search = { 
                          cari: this.cari, 
                          length : this.length,
                          start : this.start,
                          };
          axios
        .post(
          process.env.VUE_APP_BASE_URL+"members",search
        )
        .then((response) => {
          this.mySpinner = false;
          this.items=response.data.data;
          this.itemlengths = this.items.length;

          // alert(JSON.stringify(this.items));
        });
      if (this.tahun == null){
      // your code here.
        // axios
        // .get("https://probowsolution.com/sippd/public/User/2021?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
          // this.products = response.data;
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"members",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "member_name" },
                  { "data": "member_email" },
                  { "data": "member_country", class:"text-center" },
                  { "data": "last_activity", class:"text-center" },
                  { "data": "assets", class:"text-center" },
                  { "data": "edit", class:"text-center" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        // axios
        // .get("https://probowsolution.com/sippd/api/public/User/"+this.tahun+"?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
        //   this.products = response.data;
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"members",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "member_name" },
                  { "data": "member_email" },
                  { "data": "member_country", class:"text-center" },
                  { "data": "last_activity", class:"text-center" },
                  { "data": "assets", class:"text-center" },
                  { "data": "edit", class:"text-center" },
              ]  
          
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>